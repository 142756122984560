import React from 'react'; 
import sendMailToAdmin from '../../services/sendMailToAdmin';

import ContactImg from "../../assets/images/free-trial-img.png";

const ConsultingForm = () => { 

    const [nome, setNome] = React.useState(''); 
    const [mail, setMail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [oggetto, setOggetto] = React.useState('');
    const [testo, setTesto] = React.useState('');

    const [dialogShow, setDialogShow] = React.useState(false);

    return (
        <div className="contact-area ptb-80">
            <div className="container">
                <div className="section-title-consulting" >
                    <h2>Sviluppatori felici per il tuo progetto IT</h2>
                    <div className="bar"></div>
                    <p>
                        <br/>
                        Il tuo progetto IT ha bisogno di sviluppatori competenti, precisi e affidabili.<br/>
                        Trovarli non è semplice, lo shortage di personale IT competente è sotto gli occhi di chiunque. Lo avrai notato se ti sei messo alla ricerca di uno sviluppatore Android o iOS con esperienza, di un bravo sistemista o di un designer per un progetto di gamification per la tua campagna di marketing.
                        <br/><br/>
</p>
<p>
Molti progetti sono poi colpiti da un alto turnover che costringe a continue frenate, cambi di rotta o peggio ancora a far ripartire da zero lo sviluppo di funzionalità importanti.
<br/><br/>
</p>
<p>

In Appius abbiamo a cuore la felicità dei nostri collaboratori, è la nostra missione. I collaboratori felici lavorano meglio, hanno una concentrazione superiore e portano al cliente il risultato desiderato, nei tempi stabiliti, generando felicità anche all’interno dell’ambiente del cliente.<br/>
<br/>
Per inserire nel tuo team uno sviluppatore felice e competente, contattaci oggi stesso.<br/>
<br/>
<h2 >Fai il primo passo verso il raggiungimento dei tuoi obiettivi, e ottieni tutte le informazioni che ti occorrono.</h2>
</p>
                </div>

                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <img src={ContactImg} alt="image" />
                    </div>

                    <div className="col-lg-6 col-md-12">
                            <div className="row">
                            <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <input 
                                            type="text" 
                                            name="name" 
                                            placeholder="Il tuo nome" 
                                            className="form-control" 
                                            value={nome}
                                            onChange={event => setNome(event.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <input 
                                            type="text" 
                                            name="email" 
                                            placeholder="Il tuo indirizzo e-mail" 
                                            className="form-control"  
                                            value={mail}
                                            onChange={event => setMail(event.target.value)}
                                        /> 
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <input 
                                            type="text" 
                                            name="number" 
                                            placeholder="Il tuo numero di telefono" 
                                            className="form-control"  
                                            value={phone}
                                            onChange={event => setPhone(event.target.value)}
                                        /> 
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <input 
                                            type="text" 
                                            name="subject" 
                                            placeholder="Oggetto" 
                                            className="form-control"  
                                            value={oggetto}
                                            onChange={event => setOggetto(event.target.value)}
                                        /> 
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <textarea 
                                            name="text" 
                                            cols="30" 
                                            rows="5" 
                                            placeholder="Scrivi il tuo messaggio..." 
                                            className="form-control"  
                                            value={testo}
                                            onChange={event => setTesto(event.target.value)}
                                        /> 
                                    </div>
                                </div>
            
                                <div className="col-lg-12 col-sm-12">
                                    <button className="btn btn-primary" onClick={async () => {
                                        await sendMailToAdmin('INFO NUOVO LEAD ', mail, nome, phone, oggetto, testo);
                                        setDialogShow(true);
                                    }}>Richiedi INFO</button>
                                </div>
                            </div>
                    </div> 
                </div>
            </div>
        </div>
    )
}

export default ConsultingForm;  