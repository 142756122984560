import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo";
import Navbar from "../components/_App/NavbarStyleFour";
import Footer from "../components/_App/Footer";
import ConsultingForm from "../components/Contact/ConsultingForm";
import { logPage } from "../analytics/analytics";

const HappyDevelopers = () => {
    logPage("Consulting");

    return (
        <Layout>
            <SEO title="Sviluppatori felici per il tuo progetto IT" />

            <Navbar />

            <ConsultingForm />

            <Footer />
        </Layout>
    );
};

export default HappyDevelopers;
